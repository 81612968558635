/* eslint-disable max-lines */
import { FieldTypes } from 'partnerslate-models'

export default {
  general: {
    emptyString: ' ',
    formErrors: 'There was an error processing your request.',
    help: 'Help',
    plansAndPricing: 'Plans & Pricing',
    logIn: 'Log In',
    logout: 'Logout',
    signUp: 'Sign Up',
    leads: 'Leads',
    profile: 'Profile',
    visitor: 'Visitor',
    accountSettings: 'Settings',
    projects: 'Projects',
    search: 'Search',
    iAgreeTo: "I agree to PartnerSlate's",
    tos: 'Terms of Service',
    tou: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    iAgree: 'I agree to PartnerSlate’s Terms of Service & Privacy Policy. *',
    success: 'Success',
    error: 'Error',
    unableToCompleteAction: 'Unable to complete action',
    phoneFormat: 'xxx xxx xxxx',
    [FieldTypes.servicesOffered]: 'Business Services Offered',
    [FieldTypes.manufacturerType]: 'Manufacturer Type',
    [FieldTypes.distributionType]: 'Distribution',
    [FieldTypes.brandStage]: 'Company Revenue',
    [FieldTypes.productCategories]: 'Product Category',
    [FieldTypes.facilityLocation]: 'Facility Location',
    [FieldTypes.numberOfFacilityLocations]: 'Number Of Facility Locations',
    [FieldTypes.packagingFormats]: 'Packaging Format',
    [FieldTypes.processingCapabilities]: 'Processing Capabilities',
    [FieldTypes.certifications]: 'Certifications',
    [FieldTypes.productionRunSize]: 'Production Run Size',
    [FieldTypes.additionalServices]: 'Additional Services',
    [FieldTypes.businessType]: 'Business Type',
    [FieldTypes.comanPreviousBrandSizes]: 'Brand Sizes you have worked with',
    [FieldTypes.diverseBackgrounds]:
      'Company founded, led or owned by someone from a diverse background?',
    [FieldTypes.diverseSocialImpacts]:
      "Is there a social impact component to your company's mission?",
    cancel: 'Cancel',
    apply: 'Apply',
    clearAll: 'Clear All',
    zoom: 'Zoom',
    close: 'Close',
    camera: 'Camera',
    save: 'Save',
    saveChanges: 'Save changes',
    submit: 'Submit',
    editInformation: 'Edit information',
    ok: 'OK',
    next: 'Next',
    back: 'Back',
    send: 'Send',
    pageNotFound: 'Page not found',
    weCantFind: 'We can’t seem to find the page you’re looking for.',
    goBack: 'Go back',
    thankYou: 'Thank you!',
  },
  inputs: {
    enterHere: 'Enter Here',
    phone: {
      error: 'Please enter a valid phone number.',
    },
    companyName: {
      error: 'Please provide your company name.',
    },
    firstName: {
      error: 'Please provide your first name.',
    },
    lastName: {
      error: 'Please provide your last name.',
    },
    tos: {
      error: 'Please accept Terms of Use to continue.',
    },
    currentDistribution: {
      error: 'Please select you Current Distribution.',
    },
    servicesOffered: {
      error: 'Please select your business services',
    },
    howDidYouHearAboutUs: {
      error: 'Please tell us how you heard about us',
    },
    website: {
      error: 'Please provide a valid URL.',
    },
    validation: {
      maxChar: (max: number): string => `This field must contain less than ${max} characters.`,
    },
  },
  forms: {
    changesNotSaved: {
      title: 'Your changes have not yet been saved.',
      subtitle: 'Do you want to keep your changes?',
      continueWithoutSavingButton: 'Continue without Saving',
      saveAndContinueButton: 'Save and Continue',
    },
  },
  auth: {
    passwordValidErr: {
      min: 'Must be at least 8 characters.',
      max: 'Do not exceed 50 characters.',
      oneNumber: 'Contain at least one number.',
      oneSpecialChar: 'Contain at least one special character.',
      required: 'Password is required',
    },
    resetPassword: {
      title: 'Forgot Your Password?',
      subtitle: 'Please enter your email below to receive your password reset instructions.',
      success: {
        subtitle: 'If you have an account, a reset link was sent to your email.',
        nextButton: 'Home',
      },
    },
    resetPasswordChange: {
      title: 'Reset Your Password',
      subtitle: 'Please enter your new password.',
      nextButton: 'Reset Password',
      success: {
        subtitle: 'Password changed successfully please log in to continue',
        nextButton: 'Home',
      },
      enterNewPassword: 'Enter New Password',
      confirmNewPassword: 'Confirm New Password',
    },
    login: {
      title: 'Welcome Back!',
      email: 'Email',
      enterEmail: 'Enter email address',
      password: 'Password',
      enterPassword: 'Enter password',
      signIn: 'Log In',
      forgotPassword: 'Forgot Password?',
      haveAnAccount: "Don't Have An Account? ",
      signup: 'Sign Up',
    },
    signup: {
      email: 'Enter email address',
      passwordSubtext:
        'Password must be 8 characters and contain at least one letter, one number and one special character.',
      confirmRequired: 'Confirm password is required',
      inputEmail: 'Email address',
      inputPassword: 'Create Password',
      enterPassword: 'Enter password',
      inputPasswordAgain: 'Confirm Password',
      enterPasswordAgain: 'Enter password again',
      firstName: 'First name',
      enterFirstName: 'Enter first name',
      lastName: 'Last name',
      enterLastName: 'Enter last name',
      passwordMatchError: 'Passwords do not match.',
      jobTitle: 'Job title',
      enterJobTitle: 'Enter job title',
      companyDetails: {
        companyName: 'Company name *',
        enterCompanyName: 'Enter company name',
        companyPhone: 'Company phone number *',
        enterPhone: 'Enter company phone number',
      },
    },
  },
  profile: {
    profileName: (name: string): string => `${name} Profile`,
    editProfile: 'Edit Profile',
    saveProfile: 'Save Profile',
    editField: (field: string): string => `Edit ${field}`,
    deleteImage: 'Delete image',
    deleteBanner: 'Delete banner',
    emptyImage: 'Empty Image',
    addImage: 'Add Image',
    website: 'Website',
    websitePlaceholder: 'Your Website URL',
    about: 'About',
    morePhotos: 'Photos',
    aboutPlaceholder: 'Please provide a brief description of your company here...',
    yourServicesAndCapabilities: 'Your Services & Capabilities',
    pleaseProvideServiceAndCapabilities:
      'Please provide information about your services and capabilities here...',
    editPhoto: 'Position and size your photo',
    minImageSizeTitle: 'Your picture is too small',
    minImageSize: (width: number, height: number): string =>
      `Choose a JPG or PNG picture that is at least ${width}px / ${height}px.`,
    deleteProfilePhoto: 'Delete Profile Photo',
    yourInformation: 'Your Information',
    changePassword: 'Change Password',
    deleteAccount: 'Delete Account',
    currentPassword: 'Current password *',
    newPassword: 'New password *',
    confirmNewPassword: 'Confirm new password *',
    enterYourCurrentPassword: 'Enter your current password',
    enterYourNewPassword: 'Enter your new password',
    confirmYourNewPassword: 'Confirm your new password',
    areYouSureYouWant: 'Are you sure you want to delete your account?',
    ifYouDelete:
      'If you delete your account, you and your team will permanently lose all your history and content associated with Partner Slate.',
    companyDetails: 'Company Details',
    companyType: 'Company Type',
    brand: 'Brand',
    manufacturer: 'Manufacturer',
    subscriptions: 'Subscriptions',
    manageSubscriptions: 'Manage your PartnerSlate Subscription or ',
    learnMoreAboutSubscriptions: 'Learn More About Subscriptions.',
    basic: 'Basic',
    foreverFree: 'Free to Signup.',
    detailedBrand: 'Detailed Brand Profile',
    createProject: 'Create a Project',
    submitGeneral: 'Submit a General Inquiry',
    accessLearning: 'Access Learning Center Content',
    fullSearchAccess: 'Full Access to Manufacturer Search Platform',
    createAdditional: 'Create Additional Projects',
    accessPremium: 'Access Premium Learning Center Content',
    unlimitedInquires: 'Unlimited Manufacturer Inquires',
    DedicatedSupport: 'Dedicated PartnerSlate Support',
    currentPlan: 'This is your current  plan',
    premium: 'Premium',
    premiumDescription:
      'For brands looking to find their next production partner and streamline their operations.',
    upgrade: 'Upgrade',
    upgradePlan: 'Upgrade Plan',
    downgrade: 'Downgrade',
    expires: (expiry: string): string => `Subscription expires on ${expiry}`,
    noInfo: "You don't have information to display yet.",
    noInfoOther: "There isn't information to display yet.",
    paymentSettings: 'Payment Settings',
    thisCardWillBeUsed: 'This card will be used for any active premium subscription.',
    nameOnCard: 'Name on card',
    cardNumber: 'Card number',
    cardExpiration: 'Card expiration',
    cvc: 'CVC',
    johnSmith: 'John Smith',
    nameOnCardError: 'Card name required',
    unableToSavePaymentMethod: 'Unable to save payment method',
    deleteCard: 'Delete card',
    areYouSureDeleteCard: 'Are you sure you want to delete your card?',
    deleteCardDetails:
      'If you delete your account, your subscription will end. Please add a new card if you’d like to keep an active subscription.',
    youAreNowPremium: 'You are now a premium user!',
    allSetWithPremium:
      "You're all set to start finding and inquiring with awesome partners for your next product.",
    downgradePlan: 'Downgrade Your Plan',
    areYouSureDowngrade:
      "Are you sure you'd like to switch to the free plan? Once your paid period is up, you'll immedietly lose full access to the search platform as well as any additoinal projects on your account.",
    keepPremium: 'Keep premium plan',
    switchToFree: 'Switch to free plan',
    imageOf: (current: number, total: number): string => `Image ${current} of ${total}`,
  },
  close: 'close',
  loading: 'Loading',
  search: {
    placeholder: 'Search companies or keywords',
    sorry: "We're sorry!",
    cantFindPartners: "We can't seem to find partners that match your search terms.",
    tryAgain: 'Try removing filters or simplifying your search terms.',
    filters: 'Filters',
    selected: 'Selected',
    paywall: {
      title: (results: number): string => `Your Search Returned ${results} Results`,
      para1:
        'Full access to the search platform is a premium feature. Please get upgraded to begin searching and inquiring with specific manufacturers.',
      para2:
        "If you'd like to submit a General Inquiry which is included in your free plan, you can get started by creating a project!",
      goToProjects: 'Go to Projects',
      checkPremium: 'Check out Premium',
    },
  },
  projects: {
    title: 'Projects',
    createNew: 'Create New Project',
    view: 'View Project',
    edit: 'Edit Project',
    placeholderName: 'Project Name',
    pleaseCorrect: 'Please correct the errors below.',
    certificationNotSupported: 'This certification is not supported by this manufacturer.',
    categoryNotSupported: 'This category is not supported by this manufacturer.',
    packagingFormatNotSupported: 'This packaging format is not supported by this manufacturer.',
    details: {
      allThatApply: 'Please select all that apply',
      selectOne: 'Please select an option',
      titlePlaceholder: 'New Project',
      projectInfo: {
        title: 'Project Information',
        projectName: 'Project Name',
        projectNamePlaceholder: 'eg: Crunchy Protein Bars',
        projectCategory: 'Project Category',
        skus: 'SKUs/Flavors',
        skusPlaceholder: 'Enter number of SKUs',
        certificationRequirements: 'Certification Requirements',
        productStatus: {
          title: 'Product Status',
          market: 'In the market',
          dev: 'In development',
        },
        projectDescription: 'Project Description',
        projectDescriptionPlaceholder:
          'Describe your product to prospective partners. Avoid using product names that would reveal your identity',
      },
      volumeProjections: {
        runSize: 'Run Size Volume per SKU (in units)',
        annualVolume: 'Annual Volume per SKU (in units)',
        volumePlaceholder: '50,000',
      },
      packagingDetails: {
        title: 'Packaging Details',
        packagingFormat: 'Packaging Format',
      },
      productionInfo: {
        title: 'Production Information',
        specialEquipmentNeeded: 'Equipment Needed',
        specialEquipmentNeededPlaceholder: 'List any equipment required for your project',
        rAndDNeeded: {
          title: 'Formulation Help/R&D Needed',
          yes: 'Yes',
          no: 'No',
          iDontKnow: "I Don't Know",
        },
        additionalNotes: 'Additional Project Notes',
        additionalNotesPlaceholder: 'Add any additional notes about your project',
        allergensPresent: 'Allergens Present in the Product',
      },
      validation: {
        fieldRequired: 'This field is required',
        numberError: 'Please enter a number',
      },
    },
  },
  inquiry: {
    noRequiredCertifications: 'No required certifications',
    thankYou:
      'Thanks so much for getting  your project submitted for PartnerSlate Matching. Our team will review your project and reach out directly about setting up a call to discuss next steps!',
  },
}
