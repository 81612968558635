/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { formatHelpers } from '@partnerslate/core'
import { ProjectDetail } from '@partnerslate/ui-components/'

import { Address } from './address'

export const MilestoneStatus = {
  NotStarted: 'NOT_STARTED',
  InProgress: 'IN_PROGRESS',
  Completed: 'COMPLETED',
} as const
export type MilestoneStatusType = typeof MilestoneStatus[keyof typeof MilestoneStatus]

export interface Milestone {
  title: string
  status: MilestoneStatusType
  detail: string
  stage: MilestonesEngagementStageKey
}

interface MilestoneMarkers {
  title: string
  associatedStage: MilestonesEngagementStageKey
  detail: string
}

export type EngagementProject = BaseProject & ProjectDynamicAttrs

export interface BoxAccessDetails {
  folderId: string
}

export interface MessageChannelConnection {
  channelId: string
}

export const EngagementPerspective = {
  Brand: 'brand',
  Partner: 'partner',
} as const
export type EngagementPerspectiveType =
  typeof EngagementPerspective[keyof typeof EngagementPerspective]

export type SystemEngagementStages = 'nda'

export const ActiveEngagementStages = [
  'initial_discussion',
  'tech_review',
  'trial_and_development',
  'negotiating_agreement',
  'pre_production',
  'production',
] as const
export type ActiveEngagementStages = typeof ActiveEngagementStages[number]

export const ActiveEngagementStagesWithNda = ['nda', ...ActiveEngagementStages] as const
export type ActiveEngagementStagesWithNda = typeof ActiveEngagementStagesWithNda[number]

const InactiveEngagementStages = ['inactive_no_longer_interested', 'inactive_on_hold'] as const
type InactiveEngagementStages = typeof InactiveEngagementStages[number]

export const ComanInactiveEngagementStages = [
  ...InactiveEngagementStages,
  'inactive_brand_unresponsive',
] as const
export type ComanInactiveEngagementStages = typeof ComanInactiveEngagementStages[number]

export type ComanEditableEngagementStages = ActiveEngagementStages | ComanInactiveEngagementStages

export const AllPossibleComanEngagementStages = [
  ...ActiveEngagementStagesWithNda,
  ...ComanInactiveEngagementStages,
] as const
export type AllPossibleComanEngagementStages = typeof AllPossibleComanEngagementStages[number]

export const BrandInactiveEngagementStages = [
  ...InactiveEngagementStages,
  'inactive_coman_unresponsive',
] as const
export type BrandInactiveEngagementStages = typeof BrandInactiveEngagementStages[number]

export const AllInactiveEngagementStages = [
  ...InactiveEngagementStages,
  'inactive_brand_unresponsive',
  'inactive_coman_unresponsive',
] as const
export type AllInactiveEngagementStages = typeof AllInactiveEngagementStages[number]

export type BrandEditableEngagementStages = ActiveEngagementStages | BrandInactiveEngagementStages

// we can remove this EditableEngagementStages and EngagementStageKey once we fully use
// ComanEditableEngagementStages | BrandEditableEngagementStages
export type EditableEngagementStages = ActiveEngagementStages
export type EngagementStageKey = EditableEngagementStages | 'nda'

export type MilestonesEngagementStageKey =
  | ComanEditableEngagementStages
  | BrandEditableEngagementStages
  | 'nda'

export type EngagementInactiveStateKey =
  | 'NOT_INACTIVE'
  | 'INACTIVE_NO_LONGER_INTERESTED'
  | 'INACTIVE_ON_HOLD'
  | 'INACTIVE_BRAND_UNRESPONSIVE'

export const displayInactiveState = (key: EngagementInactiveStateKey): string => {
  const inactiveStates: Record<EngagementInactiveStateKey, string> = {
    NOT_INACTIVE: 'Not Inactive',
    INACTIVE_NO_LONGER_INTERESTED: 'Inactive Dead',
    INACTIVE_ON_HOLD: 'Put on Hold',
    INACTIVE_BRAND_UNRESPONSIVE: 'Inactive Brand Unresponsive',
  }
  return inactiveStates[key]
}

export const displayTextForStage = (StageKey: MilestonesEngagementStageKey): string => {
  const StagesDict: Record<MilestonesEngagementStageKey, string> = {
    nda: 'Pending NDA',
    // eslint-disable-next-line camelcase
    initial_discussion: 'Pending Initial Discussion',
    // eslint-disable-next-line camelcase
    tech_review: 'Technical Review',
    // eslint-disable-next-line camelcase
    trial_and_development: 'Trial And Development',
    // eslint-disable-next-line camelcase
    negotiating_agreement: 'Negotiating Agreement',
    // eslint-disable-next-line camelcase
    pre_production: 'Pre-production',
    // eslint-disable-next-line camelcase
    production: 'Production',
    // eslint-disable-next-line camelcase
    inactive_no_longer_interested: 'Inactive Dead',
    // eslint-disable-next-line camelcase
    inactive_on_hold: 'Inactive - On hold',
    // eslint-disable-next-line camelcase
    inactive_brand_unresponsive: 'Inactive Brand Unresponsive',
    // eslint-disable-next-line camelcase
    inactive_coman_unresponsive: 'Inactive Coman Unresponsive',
  }
  return StagesDict[StageKey]
}

export interface EngagementParticipant {
  id: string
  companyName: string
  phone: string | null
  fullAddress: Address
  contactName: string
  contactEmail: string
  slug: string
  website: string
  calendlyUrl: string | null
}

export type NdaSigningState =
  | 'no_nda'
  | 'unsigned'
  | 'only_signed_by_brand'
  | 'only_signed_by_partner'
  | 'fully_signed'
export type NdaSigningMethod = 'embedded' | 'email'
export type NdaSource = 'partnerslate' | 'partner' | 'brand'
type NdaSigners = {
  brand: {
    email: string
    name: string
  }
  partner: {
    email: string
    name: string
  }
}

export interface Nda {
  signingState: NdaSigningState
  signingMethod: NdaSigningMethod
  ndaSource: NdaSource
  signingUrl: string | null
  pdfUrl: string | null
  signers: NdaSigners
  remindedAt: Date | null
}

export type BrandTodosType =
  | 'review-project-brief'
  | 'share-product-formula'
  | 'share-supporting-documents'
  | 'schedule-intro-call'
  | 'send-samples'
  | 'site-visit'
  | 'benchtop-samples'
  | 'trial-run'
  | 'receive-quote'
  | 'ingredient-rocurement'
  | 'contract'
  | 'production-run'

export type ComanTodosType =
  | 'review-project-brief'
  | 'review-additional-shared-documents'
  | 'schedule-intro-call'

export type TodoKeysType = BrandTodosType | ComanTodosType

export interface Engagement {
  id: string
  createdAt: Date
  nda: Nda | null
  perspective: EngagementPerspectiveType
  stage: ComanEditableEngagementStages | BrandEditableEngagementStages | 'nda'
  ndaStage: NdaSigningState
  coman: EngagementParticipant
  brand: EngagementParticipant
  project: EngagementProject
  boxAccessDetails: BoxAccessDetails | null
  intraTeamMessages: MessageChannelConnection | null
  interTeamMessages: MessageChannelConnection | null
  completedTodos: TodoKeysType[]
}

export const isInactiveOrInNdaStage = (engagement: Engagement): boolean => {
  return (
    (isEngagementInactive(engagement) || engagement.stage === 'nda') &&
    !['no_nda', 'fully_signed'].includes(engagement.ndaStage)
  )
}

export const needsOurSignature = (engagement: Engagement): boolean => {
  if (!engagement.nda) {
    return false
  }

  switch (engagement.nda.signingState) {
    case 'no_nda':
      return false
    case 'unsigned':
      return true
    case 'fully_signed':
      return false
    case 'only_signed_by_brand':
      return engagement.perspective !== 'brand'
    case 'only_signed_by_partner':
      return engagement.perspective !== 'partner'
  }
}

export const hasPartySigned = (engagement: Engagement, party: string): boolean => {
  if (!engagement.nda) {
    return false
  }

  switch (engagement.nda.signingState) {
    case 'no_nda':
      return false
    case 'unsigned':
      return false
    case 'fully_signed':
      return true
    case 'only_signed_by_brand':
      return party === 'brand'
    case 'only_signed_by_partner':
      return party === 'partner'
  }
}

export const isEngagementInactive = (engagement: Engagement): boolean => {
  // why we need the `as`: https://stackoverflow.com/a/56745484/53529
  return (AllInactiveEngagementStages as ReadonlyArray<string>).includes(engagement.stage)
}

export const hasOffPlatformNDA = (engagement: Engagement): boolean => {
  return engagement.ndaStage === 'no_nda' && engagement.stage !== 'nda'
}

const MILESTONE_SEQUENCE: Array<MilestoneMarkers> = [
  {
    title: 'Sign NDA',
    associatedStage: 'nda',
    detail: 'Protect confidential information for both parties',
  },
  {
    title: 'Pending Initial Discussion',
    associatedStage: 'initial_discussion',
    detail: 'First Call to determine project fit',
  },
  {
    title: 'Technical Review',
    associatedStage: 'tech_review',
    detail: 'Discuss Project details with your partner',
  },
  {
    title: 'Trial & Development',
    associatedStage: 'trial_and_development',
    detail: 'Work through test runs and product development needs',
  },
  {
    title: 'Negotiating Agreement',
    associatedStage: 'negotiating_agreement',
    detail: 'Agree on pricing and terms for production',
  },
  {
    title: 'Pre-production',
    associatedStage: 'pre_production',
    detail:
      'Parties have agreed on production run and are preparing, e.g. waiting for delivery of supplies',
  },
  { title: 'Production', associatedStage: 'production', detail: 'Successfully engaged' },
]

export const milestonesForEngagement = (engagement: Engagement): Array<Milestone> => {
  let beyondAssociatedStage = false
  return MILESTONE_SEQUENCE.map((m) => {
    if (beyondAssociatedStage) {
      return {
        title: m.title,
        detail: m.detail,
        status: MilestoneStatus.NotStarted,
        stage: m.associatedStage,
      }
    }

    if (m.associatedStage === engagement.stage || engagement.ndaStage !== 'fully_signed') {
      beyondAssociatedStage = true
      return {
        title: m.title,
        detail: m.detail,
        status: MilestoneStatus.InProgress,
        stage: m.associatedStage,
      }
    }

    return {
      title: m.title,
      detail: m.detail,
      status: MilestoneStatus.Completed,
      stage: m.associatedStage,
    }
  })
}

export const isTodoCompleted = (engagement: Engagement, todoKey: TodoKeysType): boolean => {
  return engagement.completedTodos.includes(todoKey)
}

export type StatusDisplay = {
  name: string
  description?: string
  textColor: string
}
export type EngagementStatusDisplay = MilestonesEngagementStageKey

const COMMON_ENGAGEMENT_STATUSES: Record<
  ActiveEngagementStagesWithNda | InactiveEngagementStages,
  StatusDisplay
> = {
  nda: { name: 'NDA', description: 'NDA not fully signed', textColor: 'warning.main' },
  initial_discussion: {
    name: 'Pending Initial Discussion',
    textColor: 'info.main',
    description: 'First Call to determine project fit',
  },
  tech_review: {
    name: 'Tech Review',
    textColor: 'info.main',
    description: 'Have begun exchange of technical documents to determine feasibility',
  },
  trial_and_development: {
    name: 'Trial & Development',
    textColor: 'success.main',
    description: ' Have scheduled or are working through trial runs or product development',
  },
  negotiating_agreement: {
    name: 'Negotiating Agreement',
    textColor: 'success.main',
    description: 'Negotiating a contract or other production agreement',
  },
  pre_production: {
    name: 'Pre-production',
    textColor: 'success.main',
    description:
      'Have agreed on production run and are preparing, e.g. waiting for delivery of supplies',
  },
  production: {
    name: 'Production',
    textColor: 'success.main',
    description: 'PO is received and production is scheduled or complete',
  },
  inactive_no_longer_interested: {
    name: 'Dead',
    textColor: 'text.secondary',
    description: 'Not pursuing this engagement anymore',
  },
  inactive_on_hold: {
    name: 'Put On Hold',
    textColor: 'text.secondary',
    description:
      'Pausing this engagement for the time being but planning to re-engage in the future',
  },
}
export const ENGAGEMENT_STATUSES_BY_BRAND: Record<
  SystemEngagementStages | BrandEditableEngagementStages,
  StatusDisplay
> = {
  ...COMMON_ENGAGEMENT_STATUSES,
  inactive_coman_unresponsive: {
    name: 'Coman Unresponsive',
    textColor: 'text.secondary',
    description: 'Inactive - the co-manufacturer has not been responsive',
  },
}

ENGAGEMENT_STATUSES_BY_BRAND.inactive_no_longer_interested = {
  name: 'Dead',
  textColor: 'text.secondary',
  description: 'Dead - No longer interested in working with this co-manufacturer',
}

export const ENGAGEMENT_STATUSES_BY_COMAN: Record<
  SystemEngagementStages | ComanEditableEngagementStages,
  StatusDisplay
> = {
  ...COMMON_ENGAGEMENT_STATUSES,
  inactive_brand_unresponsive: {
    name: 'Brand Unresponsive',
    textColor: 'text.secondary',
    description:
      'The Brand has not responded to my outreach. PartnerSlate can help with outreach to the Brand',
  },
}

// [START] Remove when is moved to monorepo
export const PROJECT_PUBLISH_STATUS_KEYS = [
  'PUBLISHED',
  'UNPUBLISHED',
  'SUBMITTED',
  'INCOMPLETE',
] as const

export type ProjectPublishStatuses = typeof PROJECT_PUBLISH_STATUS_KEYS[number]

export type BaseProject = {
  id: string
  createdAt: Date
  marketplacePaidAt: Date
  category: ReferenceGroup
  name: string
  skus: number
  runSizeVolumePerSku: number
  description: string
  status: ProductStatus
  summary: string
  certificationRequirements: string[]
  annualVolumePerSku: number
  msrp: number
  serviceModel: ServiceModel
  packagingFormat: ReferenceGroup
  specialEquipmentNeeded: string
  rAndDNeeded: RAndDNeeded
  additionalNotes: string
  allergensPresent: string[]
  publishedToMarketplaceAt: Date | null
  publishStatus: ProjectPublishStatuses
  yearFounded: string
  brandStage: string
  address: {
    state: string
  }
}

export type ProjectDynamicAttrs = {
  commercialFormula: CommercialFormula | null
  aboutUs: string | null
  processAuthorityLetter: ProcessAuthorityLetter | null
  ingredientsSourcing: IngredientsSourcing | null
  commercializingSupport: CommercializingSupport | null
  formulationHelpLevel: FormulationHelpLevel | null
  formulationHelpType: FormulationHelpType[] | null
  formulationHelpDescription: string | null
  reasonToLookForComan: typeof REASON_TO_LOOK_FOR_COMAN[number][] | null
  processingSteps: string[] | null
  openToAlternateProcessing: OpenToAlternateProcessing | null
  postProductionStorage: PostProductionStorage | null
  openToEquipmentInvestment: OpenToEquipmentInvestment | null
  packagingRequired: PackagingRequired | null
  packagingRequiredReasons: PackagingRequiredReason[] | null
  msrp: number | null
  hideMsrpFromListing: boolean
  restrictedIngredients: RestrictedIngredients[] | null
  targetDateFirstRun: TargetDateFirstRun | null
  additionalVolumeComments: string | null
  needGFSIFacility: NeedGFSIFacility | null
  geoPreference: string | null
  geoPreferenceImportance: GeoPreferenceImportance | null
  serviceModel: ServiceModel | null
  needComanFullfillment: NeedComanFullfillment | null
  comansToAvoid: string | null
  distributedProduct: DistributedProduct[] | null
  currentDistributors: string | null
  currentNotableRetailers: string | null
  currentDoorCount: number | null
  futureNotableRetailers: string | null
  futureDistributors: string | null
  futureDoorCount: number | null
  typeOfPartnership: TypeOfPartnership[] | null
  needSpecialtyIngredientsSourcing: string | null
  kindsOfSpecialtyIngredients: KindOfSpecialtyIngredient[] | null
  otherKindOfSpecialtyIngredients: string | null
  anyIngredientsRequirement: string | null
}

export type ReferenceGroup = {
  id: number
  active: boolean
  label: string
  group: {
    id: number
    name: string
    order: number
  }
}

export const PRODUCT_STATUS_KEYS = ['market', 'retail', 'development'] as const
export type ProductStatus = typeof PRODUCT_STATUS_KEYS[number]
export const getProductStatus = (statusKey: ProductStatus): string => {
  const statuses: Record<ProductStatus, string> = {
    development: 'Currently in Development',
    retail: 'Retail Ready (i.e. nutrition facts, ingredient deck, barcodes, etc)',
    market: 'Currently in Market',
  }
  return statuses[statusKey]
}

export const R_AND_D_NEEDED_KEYS = ['yes', 'no', 'i_dont_know'] as const
export type RAndDNeeded = typeof R_AND_D_NEEDED_KEYS[number]
export const getRAndDNeeded = (statusKey: RAndDNeeded): string => {
  const statuses: Record<RAndDNeeded, string> = {
    yes: 'Yes',
    no: 'No',
    i_dont_know: 'Unknown',
  }
  return statuses[statusKey]
}

export const FORMULATION_HELP_KEYS = [
  'END_TO_END',
  'FORMULATION_ONLY',
  'SCALE_UP',
  'OTHER',
] as const
export type FormulationHelp = typeof FORMULATION_HELP_KEYS[number]
export function getFormulationHelp(statusKey: FormulationHelp): string {
  const statuses: Record<FormulationHelp, string> = {
    END_TO_END: 'Full Formulation',
    FORMULATION_ONLY: 'Partial Formulation',
    SCALE_UP: 'Scale up Formulation',
    OTHER: 'Other / Not sure',
  }
  return statuses[statusKey]
}

export const SERVICE_MODEL_KEYS = [
  'TURNKEY',
  'TOLLING',
  'OPEN_TO_EITHER',
  'HYBRID',
  'I_DONT_KNOW',
] as const
export type ServiceModel = typeof SERVICE_MODEL_KEYS[number]
export const getServiceModel = (key: ServiceModel | null): string => {
  if (key) {
    const models: Record<ServiceModel, string> = {
      TURNKEY: 'Turnkey',
      TOLLING: 'Tolling',
      OPEN_TO_EITHER: 'Open to either',
      HYBRID: 'Hybrid',
      I_DONT_KNOW: 'Not specified',
    }
    return models[key]
  }
  return ''
}

export const OPEN_TO_ALTERNATE_PROCESSING_KEYS = [
  'VERY_OPEN',
  'SOMEWHAT_OPEN',
  'ONLY_IF_NO_OTHER_OPTION',
  'NO',
] as const
export type OpenToAlternateProcessing = typeof OPEN_TO_ALTERNATE_PROCESSING_KEYS[number]
export const getOpenToAlternateProcessing = (statusKey: OpenToAlternateProcessing): string => {
  const statuses: Record<OpenToAlternateProcessing, string> = {
    VERY_OPEN: 'Very open',
    SOMEWHAT_OPEN: 'Somewhat open',
    ONLY_IF_NO_OTHER_OPTION: 'Only if no other option',
    NO: 'No',
  }
  return statuses[statusKey]
}

export const POST_PRODUCTION_STORAGE_KEYS = [
  'FROZEN',
  'REFRIGERATED',
  'TEMP_CONTROLLED',
  'AMBIENT',
] as const
export type PostProductionStorage = typeof POST_PRODUCTION_STORAGE_KEYS[number]
export const getPostProductionStorage = (statusKey: PostProductionStorage): string => {
  const statuses: Record<PostProductionStorage, string> = {
    FROZEN: 'Frozen: at or below 0°F (-18°C)',
    REFRIGERATED: 'Refrigerated: 32°F to 40°F (0°C to 5°C)',
    TEMP_CONTROLLED: 'Temperature Controlled: 56°F to 75°F (13°C to 24°C)',
    AMBIENT: 'Ambient: 59°F up to 86°F (15°C to 30°C) depending on climatic conditions',
  }
  return statuses[statusKey]
}

export const OPEN_TO_EQUIPMENT_INVESTMENT_KEYS = ['YES', 'MAYBE', 'NO'] as const
export type OpenToEquipmentInvestment = typeof OPEN_TO_EQUIPMENT_INVESTMENT_KEYS[number]
export const getOpenToEquipmentInvestment = (statusKey: OpenToEquipmentInvestment): string => {
  const statuses: Record<OpenToEquipmentInvestment, string> = {
    YES: 'Yes',
    MAYBE: 'Maybe',
    NO: 'No',
  }
  return statuses[statusKey]
}

export const PROCESSING_STEPS = [
  'Pasteurization - Aseptic Fill',
  'Cooking - Baking',
  'Cooling - Blast Freezing',
  'Process - Blending, Dry (e.g. Powders, Agglomeration)',
  'Process - Blending, Wet',
  'Liquid - Brewing',
  'Liquid - Cold Fill',
  'Process - Cold Form',
  'Process - Dehydrating',
  'Process - Drizzling',
  'Process - Enrobing',
  'Pasteurization - ESL (Extended Shelf Life)',
  'Process - Extruding',
  'Pasteurization - Flash',
  'Process - Freeze Drying',
  'Cooking - Frying',
  'Process - Grinding',
  'Pasteurization - HPP (High Pressuring Processing)',
  'Pasteurization - HTST (High Temp Short Time)',
  'Liquid - Homogenization',
  'Liquid - Hot Fill',
  'Cooking - Kettle',
  'Process - Pan Coating',
  'Pasteurization - Retort',
  'Cooking - Roasting / Grilling',
  'Process - Rotary Molding',
  'Process - Sheeting',
  'Process - Smoking',
  'Process - Spray Drying',
  'Cooking - Steaming',
  'Pasteurization - Tunnel',
  'Liquid - Carbonation',
  'Liquid - Nitrogenation / Nitro-Dosing',
  'Process - Fermentation',
  'Process - Juicing / Pulping',
  'Liquid - In-line Filtration',
  'Liquid - In-tank Mixing',
]

export const RESTRICTED_INGREDIENT_KEYS = ['ALCOHOL', 'CBD', 'CANNABIS'] as const
export type RestrictedIngredients = typeof RESTRICTED_INGREDIENT_KEYS[number]
export const getRestrictedIngredients = (statusKey: RestrictedIngredients): string => {
  const statuses: Record<RestrictedIngredients, string> = {
    ALCOHOL: 'Alcohol',
    CBD: 'CBD',
    CANNABIS: 'Cannabis',
  }
  return statuses[statusKey]
}

export const COMMERCIAL_FORMULA_KEYS = ['YES', 'NO', 'IN_PROCESS'] as const
export type CommercialFormula = typeof COMMERCIAL_FORMULA_KEYS[number]
export const getCommercialFormula = (statusKey: CommercialFormula): string => {
  const statuses: Record<CommercialFormula, string> = {
    YES: 'Yes',
    NO: 'No',
    IN_PROCESS: 'In-process',
  }
  return statuses[statusKey]
}

export const PACKAGING_REQUIRED_KEYS = ['YES', 'NO'] as const
export type PackagingRequired = typeof PACKAGING_REQUIRED_KEYS[number]
export const getPackagingRequired = (statusKey: PackagingRequired): string => {
  const statuses: Record<PackagingRequired, string> = {
    YES: 'Yes',
    NO: 'No',
  }
  return statuses[statusKey]
}

export const PACKAGING_REQUIRED_REASON_KEYS = [
  'VOLUME_INCREASE',
  'LOWER_MOQS',
  'FASTER_DELIVERY',
  'CUSTOMER_REQUIREMENT',
  'HIGHER_QUALITY',
] as const
export type PackagingRequiredReason = typeof PACKAGING_REQUIRED_REASON_KEYS[number]
export const getPackagingRequiredReason = (statusKey: PackagingRequiredReason): string => {
  const statuses: Record<PackagingRequiredReason, string> = {
    VOLUME_INCREASE:
      'My volumes have increased substantially. I need a larger supplier with better unit costs.',
    LOWER_MOQS: 'My current MOQs are too large. I need a supplier that can handle smaller orders.',
    FASTER_DELIVERY: 'My current lead times are too long. I need faster turnaround on delivery.',
    CUSTOMER_REQUIREMENT:
      'I need new packaging to meet retailer or customer requirements (e.g. recyclable, resealable, etc.)',
    HIGHER_QUALITY: 'I need higher quality packaging or have a change in material technical specs.',
  }
  return statuses[statusKey]
}

export const PROCESS_AUTHORITY_LETTER_KEYS = [
  'YES',
  'IN_PROCESS',
  'NO_NEED_TO_ACQUIRE',
  'NO_DO_NOT_REQUIRE',
  'UNSURE_IF_REQUIRED',
] as const
export type ProcessAuthorityLetter = typeof PROCESS_AUTHORITY_LETTER_KEYS[number]
export const getProcessAuthorityLetter = (statusKey: ProcessAuthorityLetter): string => {
  const statuses: Record<ProcessAuthorityLetter, string> = {
    YES: 'Yes',
    IN_PROCESS: 'In-process',
    NO_NEED_TO_ACQUIRE: 'No: need to acquire',
    NO_DO_NOT_REQUIRE: 'No: do not require',
    UNSURE_IF_REQUIRED: 'Unsure if required',
  }
  return statuses[statusKey]
}

export const INGREDIENTS_SOURCING_KEYS = ['COMMERCIAL', 'RETAIL', 'HYBRID'] as const
export type IngredientsSourcing = typeof INGREDIENTS_SOURCING_KEYS[number]
export const getIngredientsSourcing = (statusKey: IngredientsSourcing): string => {
  const statuses: Record<IngredientsSourcing, string> = {
    COMMERCIAL: 'Commercial ingredient suppliers',
    RETAIL: 'Retail ingredients (grocery / Costco / Amazon)',
    HYBRID: 'Hybrid of both',
  }
  return statuses[statusKey]
}

export const COMMERCIALIZING_SUPPORT_KEYS = ['YES', 'NO', 'NOT_SURE'] as const
export type CommercializingSupport = typeof COMMERCIALIZING_SUPPORT_KEYS[number]
export const getCommercializingSupport = (statusKey: CommercializingSupport): string => {
  const statuses: Record<CommercializingSupport, string> = {
    YES: 'Yes',
    NO: 'No',
    NOT_SURE: 'Not sure',
  }
  return statuses[statusKey]
}

export const REASON_TO_LOOK_FOR_COMAN = [
  'Looking for my first co-man',
  'Expand capacity',
  'Additional capabilities needed',
  'Better pricing',
  'Mis-alignment with current co-man',
]

export const TARGET_DATE_FIRST_RUN_KEYS = [
  '2_TO_3_MONTHS',
  '4_TO_6_MONTHS',
  '6_TO_12_MONTHS',
  '12_PLUS_MONTHS',
] as const
export type TargetDateFirstRun = typeof TARGET_DATE_FIRST_RUN_KEYS[number]
export const getTargetDateFirstRun = (statusKey: TargetDateFirstRun): string => {
  const statuses: Record<TargetDateFirstRun, string> = {
    '2_TO_3_MONTHS': '2-3 months',
    '4_TO_6_MONTHS': '4-6 months',
    '6_TO_12_MONTHS': '6-12 months',
    '12_PLUS_MONTHS': '12+ months',
  }
  return statuses[statusKey]
}

export const NEED_GFSI_FACILITY_KEYS = ['YES', 'NO', 'NOT_SURE'] as const
export type NeedGFSIFacility = typeof NEED_GFSI_FACILITY_KEYS[number]
export const getNeedGFSIFacility = (statusKey: NeedGFSIFacility): string => {
  const statuses: Record<NeedGFSIFacility, string> = {
    YES: 'Yes',
    NO: 'No',
    NOT_SURE: 'Not sure',
  }
  return statuses[statusKey]
}

export const GEOGRAPHIC_PREFERENCE_IMPORTANCE_KEYS = [
  'MANDATORY',
  'VERY',
  'SOMEWHAT',
  'SLIGHTLY',
  'NOT_AT_ALL',
] as const
export type GeoPreferenceImportance = typeof GEOGRAPHIC_PREFERENCE_IMPORTANCE_KEYS[number]
export const getGeoPreferenceImportance = (statusKey: GeoPreferenceImportance): string => {
  const statuses: Record<GeoPreferenceImportance, string> = {
    MANDATORY: 'Mandatory',
    VERY: 'Very',
    SOMEWHAT: 'Somewhat',
    SLIGHTLY: 'Slightly',
    NOT_AT_ALL: 'Not at all',
  }
  return statuses[statusKey]
}

export const NEED_COMAN_FULLFILLMENT_KEYS = ['YES', 'NO', 'NICE_TO_HAVE'] as const
export type NeedComanFullfillment = typeof NEED_COMAN_FULLFILLMENT_KEYS[number]
export const getNeedComanFullfillment = (statusKey: NeedComanFullfillment): string => {
  const statuses: Record<NeedComanFullfillment, string> = {
    YES: 'Yes',
    NO: 'No',
    NICE_TO_HAVE: 'Nice to have',
  }
  return statuses[statusKey]
}

export const DISTRIBUTED_PRODUCT_KEYS = [
  'NOT_IN_MARKET',
  'DIRECT_TO_CONSUMER',
  'LOCAL',
  'FOOD_SERVICE',
  'REGIONAL',
  'NATIONAL',
] as const
export type DistributedProduct = typeof DISTRIBUTED_PRODUCT_KEYS[number]
export const getDistributedProduct = (statusKey: DistributedProduct): string => {
  const statuses: Record<DistributedProduct, string> = {
    NOT_IN_MARKET: 'Not yet in Market',
    DIRECT_TO_CONSUMER: 'Direct to Consumer',
    LOCAL: 'Local / Self Distributed',
    FOOD_SERVICE: 'Food Service',
    REGIONAL: 'Regional',
    NATIONAL: 'National',
  }
  return statuses[statusKey]
}

export const TYPE_OF_PARTNERSHIP_KEYS = ['MANUFACTURER', 'PACKAGER', 'PRIVATE_LABEL'] as const
export type TypeOfPartnership = typeof TYPE_OF_PARTNERSHIP_KEYS[number]
export const getTypeOfPartnership = (statusKey: TypeOfPartnership): string => {
  const statuses: Record<TypeOfPartnership, string> = {
    MANUFACTURER: 'Contract Manufacturer',
    PACKAGER: 'Contract Packer',
    PRIVATE_LABEL: 'Private Label Manufacturer',
  }
  return statuses[statusKey]
}

export const FORMULATION_HELP_TYPE_KEYS = [
  'FLAVORS_COLORS_AROMAS',
  'NUTRITIONAL_PROFILE',
  'FUNCTIONAL_INGREDIENTS',
  'TEXTURE_STABILIZATION',
  'MANUFACTURING_PROCESS',
] as const
export type FormulationHelpType = typeof FORMULATION_HELP_TYPE_KEYS[number]

export function getFormulationHelpType(formulationLevelOptionKey: FormulationHelpType): string {
  const formulationTypeOptions: Record<FormulationHelpType, string> = {
    FLAVORS_COLORS_AROMAS: 'Flavors / Aromas / Color',
    NUTRITIONAL_PROFILE: 'Nutritional Profile',
    FUNCTIONAL_INGREDIENTS: 'Functional Ingredients',
    TEXTURE_STABILIZATION: 'Texture / Stabilization',
    MANUFACTURING_PROCESS: 'Manufacturing process',
  }
  return formulationTypeOptions[formulationLevelOptionKey]
}

export const KINDS_OF_SPECIALTY_INGREDIENTS_KEYS = [
  'FLAVORING_COLORS_AROMAS',
  'SEASONING_SPICES_BOTANICALS',
  'FUNCTIONAL_INGREDIENTS',
] as const
export type KindOfSpecialtyIngredient = typeof KINDS_OF_SPECIALTY_INGREDIENTS_KEYS[number]

export function getKindOfSpecialtyIngredient(
  kindOfSpecialtyIngredientOptionKey: KindOfSpecialtyIngredient,
): string {
  const formulationTypeOptions: Record<KindOfSpecialtyIngredient, string> = {
    FLAVORING_COLORS_AROMAS: 'Liquid flavorings, color, and aromas',
    SEASONING_SPICES_BOTANICALS: 'Dry seasoning blends, spices, and botanicals',
    FUNCTIONAL_INGREDIENTS: 'Functional / Nutritional ingredients',
  }
  return formulationTypeOptions[kindOfSpecialtyIngredientOptionKey]
}

export const FORMULATION_HELP_LEVEL_KEYS = [
  'PRIVATE_LABEL',
  'FULL_CUSTOM',
  'PARTIAL',
  'SCALE_UP',
] as const
export type FormulationHelpLevel = typeof FORMULATION_HELP_LEVEL_KEYS[number]

export function getFormulationHelpLevel(formulationLevelOptionKey: FormulationHelpLevel): string {
  const formulationLevelOptions: Record<FormulationHelpLevel, string> = {
    PRIVATE_LABEL: 'Private Label Formulation',
    FULL_CUSTOM: 'Full Custom Formulation',
    PARTIAL: 'Partial Formulation',
    SCALE_UP: 'Scale-Up Formulation',
  }
  return formulationLevelOptions[formulationLevelOptionKey]
}

export const formatProjectToProjectDetailContent = (project: EngagementProject): ProjectDetail => {
  const newProject = {
    ...project,
    description: project.description,
    skus: project.skus,
    annualVolumePerSku: project.annualVolumePerSku,
    runSizeVolumePerSku: project.runSizeVolumePerSku,
    state: project.address.state,
    brandStage: project.brandStage,
    yearFounded: project.yearFounded,
    publishStatusLabel: getProjectPublishStatus(project.publishStatus),
    formattedProductCategory: formatReferenceGroupToLabel(project.category),
    formattedPackagingFormat: formatReferenceGroupToLabel(project.packagingFormat),
    formattedCertificationRequirements: project.certificationRequirements,
    formattedAllergensPresent: project.allergensPresent,
    formattedProductStatus: getProductStatus(project.status),
    formattedrNdNeeded: getRAndDNeeded(project.rAndDNeeded),
    formattedServiceModel: project.serviceModel ? getServiceModel(project.serviceModel) : null,
    formattedOpenToAlternateProcessing: project.openToAlternateProcessing
      ? getOpenToAlternateProcessing(project.openToAlternateProcessing)
      : null,
    formattedPostProductionStorage: project.postProductionStorage
      ? getPostProductionStorage(project.postProductionStorage)
      : null,
    formattedOpenToEquipmentInvestment: project.openToEquipmentInvestment
      ? getOpenToEquipmentInvestment(project.openToEquipmentInvestment)
      : null,
    formattedRestrictedIngredients: project.restrictedIngredients
      ? project.restrictedIngredients.map((i: RestrictedIngredients) => getRestrictedIngredients(i))
      : null,
    formattedCommercialFormula: project.commercialFormula
      ? getCommercialFormula(project.commercialFormula)
      : null,
    formattedPackagingRequired: project.packagingRequired
      ? getPackagingRequired(project.packagingRequired)
      : null,
    formattedPackagingRequiredReasons: project.packagingRequiredReasons
      ? project.packagingRequiredReasons.map((i) => getPackagingRequiredReason(i))
      : null,
    formattedProcessAuthorityLetter: project.processAuthorityLetter
      ? getProcessAuthorityLetter(project.processAuthorityLetter)
      : null,
    formattedIngredientsSourcing: project.ingredientsSourcing
      ? getIngredientsSourcing(project.ingredientsSourcing)
      : null,
    formattedCommercializingSupport: project.commercializingSupport
      ? getCommercializingSupport(project.commercializingSupport)
      : null,
    formattedTargetDateFirstRun: project.targetDateFirstRun
      ? getTargetDateFirstRun(project.targetDateFirstRun)
      : null,
    formattedNeedGFSIFacility: project.needGFSIFacility
      ? getNeedGFSIFacility(project.needGFSIFacility)
      : null,
    formattedGeoPreferenceImportance: project.geoPreferenceImportance
      ? getGeoPreferenceImportance(project.geoPreferenceImportance)
      : null,
    formattedNeedComanFullfillment: project.needComanFullfillment
      ? getNeedComanFullfillment(project.needComanFullfillment)
      : null,
    formattedDistributedProduct: project.distributedProduct
      ? project.distributedProduct.map((p: DistributedProduct) => getDistributedProduct(p))
      : null,
    // We set unpublishedFromMarketplaceAt as null here because it's required for the ProjectDetail type
    // even if it's not used there.
    // TODO: cleanup the ProjectDetail type to only include what's needed
    unpublishedFromMarketplaceAt: null,
    typeOfPartnership: project.typeOfPartnership
      ? project.typeOfPartnership.map((i) => getTypeOfPartnership(i))
      : [],
    formattedFormulationHelpLevel: project.formulationHelpLevel
      ? getFormulationHelpLevel(project.formulationHelpLevel)
      : null,
    formattedFormulationHelpType: project.formulationHelpType
      ? project.formulationHelpType.map((p) => getFormulationHelpType(p))
      : null,
    formattedFormulationHelpDescription: project.formulationHelpDescription,
    kindsOfSpecialtyIngredients: project.kindsOfSpecialtyIngredients
      ? project.kindsOfSpecialtyIngredients.map((i) => getKindOfSpecialtyIngredient(i))
      : [],
    otherKindOfSpecialtyIngredients: project.otherKindOfSpecialtyIngredients
      ? project.otherKindOfSpecialtyIngredients
      : null,
    anyIngredientsRequirement: project.anyIngredientsRequirement
      ? project.anyIngredientsRequirement
      : null,
  }

  return newProject
}

export const formatReferenceGroupToLabel = (referenceGroup: ReferenceGroup | null): string => {
  let label = ''
  if (referenceGroup) {
    if (referenceGroup.group) {
      label = `${formatHelpers.titleCase(referenceGroup.group.name)} - `
    }
    label += referenceGroup.label
  }
  return label
}

export const getProjectPublishStatus = (statusKey: ProjectPublishStatuses): string => {
  const statuses: Record<ProjectPublishStatuses, string> = {
    PUBLISHED: 'Published',
    UNPUBLISHED: 'Unpublished',
    SUBMITTED: 'Submitted, Under review',
    INCOMPLETE: 'Incomplete',
  }
  return statuses[statusKey]
}
// [END] Remove when is moved to monorepo
