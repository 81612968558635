import { Theme } from '@mui/material/styles'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SxProps } from '@mui/system'
import { Field, useFormikContext } from 'formik'
import get from 'lodash/get'
import { FieldOption } from 'partnerslate-models'
import React from 'react'

import {
  Checkbox,
  CheckboxArray,
  EmailInput,
  Input,
  NumberInput,
  PasswordInput,
  PhoneInput,
  RadioInput,
  SearchInput,
  TextArea as BaseTextArea,
} from '@/components'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@/components/input/stripe'

const addFormikFieldProps = (name: string) => {
  const { errors, values, handleBlur, setFieldTouched, setFieldValue, setTouched, touched } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFormikContext()
  // we use _.get here because name can be a dotted path
  const error = get(touched, name) && get(errors, name)
  const value = get(values, name)
  return {
    error,
    value,
    onBlur: handleBlur,
    onFocus: () => setFieldTouched(name),
    onChange: (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | boolean
        | string
        | Array<string>
        | FieldOption
        | Array<FieldOption>,
    ) => {
      let v = e
      if (typeof e === 'object' && 'target' in e) {
        v = e.target.value
      }
      setTouched({ ...touched, [name]: true }, true)
      setFieldValue(name, v)
    },
  }
}

export function TextField({
  name,
  placeholder,
  ...rest
}: {
  name: string
  placeholder: string
}): JSX.Element {
  return <Input {...rest} placeholder={placeholder} name={name} {...addFormikFieldProps(name)} />
}

TextField.defaultProps = Input.defaultProps

export function EmailField({
  name,
  placeholder,
  ...rest
}: {
  name: string
  placeholder: string
}): JSX.Element {
  return (
    <EmailInput {...rest} name={name} placeholder={placeholder} {...addFormikFieldProps(name)} />
  )
}
EmailField.defaultProps = Input.defaultProps

export function PasswordField({
  name,
  placeholder,
  ...rest
}: {
  name: string
  placeholder: string
}): JSX.Element {
  return (
    <PasswordInput {...rest} placeholder={placeholder} name={name} {...addFormikFieldProps(name)} />
  )
}
PasswordField.defaultProps = Input.defaultProps

export function SearchField({
  name,
  placeholder,
  ...rest
}: {
  name: string
  placeholder: string
}): JSX.Element {
  return (
    <SearchInput {...rest} placeholder={placeholder} name={name} {...addFormikFieldProps(name)} />
  )
}
SearchField.defaultProps = Input.defaultProps

export function PhoneField({
  name,
  placeholder,
  ...rest
}: {
  name: string
  placeholder: string
}): JSX.Element {
  return (
    <PhoneInput {...rest} placeholder={placeholder} name={name} {...addFormikFieldProps(name)} />
  )
}
PhoneField.defaultProps = Input.defaultProps

export function TextArea({
  name,
  placeholder,
  ...rest
}: {
  name: string
  placeholder: string
}): JSX.Element {
  return (
    <BaseTextArea placeholder={placeholder} name={name} {...rest} {...addFormikFieldProps(name)} />
  )
}
TextArea.defaultProps = BaseTextArea.defaultProps

export function CheckboxField({
  name,
  ariaLabel,
  labelClassName,
  testId,
  ...rest
}: {
  name: string
  ariaLabel: string
  labelClassName?: string | undefined
  label: string | React.ReactElement
  testId?: string | undefined
}): JSX.Element {
  const { onChange, value } = addFormikFieldProps(name)
  return (
    <Checkbox
      {...rest}
      name={name}
      ariaLabel={ariaLabel}
      value={value}
      onChange={() => onChange(!value)}
      labelClassName={labelClassName}
      testId={testId}
    />
  )
}

CheckboxField.defaultProps = {
  labelClassName: undefined,
  testId: undefined,
}

export function CheckboxArrayField({
  name,
  ariaLabel,
  options,
}: {
  name: string
  options: Array<FieldOption>
  ariaLabel: string
}): JSX.Element {
  const { onChange, value } = addFormikFieldProps(name)
  return (
    <CheckboxArray
      ariaLabel={ariaLabel}
      name={name}
      onChange={onChange}
      options={options}
      value={value}
    />
  )
}

export function RadioInputField({
  name,
  options,
  ...rest
}: {
  name: string
  options: Array<FieldOption>
  testId: string | undefined
}): JSX.Element {
  return <RadioInput {...rest} options={options} {...addFormikFieldProps(name)} />
}

RadioInputField.defaultProps = RadioInput.defaultProps

export function StripeCardCvcField({
  name,
  ...rest
}: {
  name: string
  label: string
  required: boolean
  id: string
}): JSX.Element {
  const { onChange } = addFormikFieldProps(name)
  return <StripeCardCvcElement name={name} onChange={onChange} {...rest} />
}

export function StripeCardExpiryField({
  name,
  ...rest
}: {
  name: string
  label: string
  required: boolean
  id: string
}): JSX.Element {
  const { onChange } = addFormikFieldProps(name)
  return <StripeCardExpiryElement name={name} onChange={onChange} {...rest} />
}

export function StripeCardNumberField({
  name,
  ...rest
}: {
  name: string
  label: string
  required: boolean
  id: string
}): JSX.Element {
  const { onChange } = addFormikFieldProps(name)
  return <StripeCardNumberElement name={name} onChange={onChange} {...rest} />
}

export function NumberField({
  name,
  placeholder,
  ...rest
}: {
  name: string
  placeholder: string
  ariaLabel: string
  label: string
  required?: boolean
  testId?: string
}): JSX.Element {
  return (
    <NumberInput {...rest} placeholder={placeholder} name={name} {...addFormikFieldProps(name)} />
  )
}

NumberField.defaultProps = {
  testId: undefined,
  required: false,
}

export function BaseField({
  name,
  placeholder,
  component,
  fullWidth,
  sx,
  ...rest
}: {
  name: string
  placeholder?: string
  component: React.ComponentType
  fullWidth: boolean
  sx?: SxProps<Theme>
}): JSX.Element {
  return (
    <Field
      {...rest}
      placeholder={placeholder}
      name={name}
      component={component}
      fullWidth={fullWidth}
      sx={sx}
      {...addFormikFieldProps(name)}
    />
  )
}

BaseField.defaultProps = Input.defaultProps
