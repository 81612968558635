import { Grid } from '@mui/material'
import { ProjectDetail } from '@partnerslate/ui-components'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'

import { Loading } from '@/components'
import { MoveToProductionConfirmationModal } from '@/components/MoveToProductionConfirmationModal'
import {
  Engagement,
  formatProjectToProjectDetailContent,
  hasOffPlatformNDA,
  isEngagementInactive,
} from '@/domain/engagements'
import { instrumentation } from '@/helpers'
import { useEngagementDetails } from '@/helpers/services/queries'
import ProjectDetailModal from '@/screens/coman-engagements/project-detail-modal'

import FourOhFour from '../four-oh-four'
import Heading from './heading'
import Milestones from './milestones'
import ScreenContainer from './screen-container'
import { Sidebar, SidebarAccordion } from './sidebar'
import EngagementStageNda from './stages/nda'
import EngagementStageTechnicalReview from './stages/technical-review'

type EngagementDetailsProps = {
  engagementId: string
  engagement: Engagement
}

// Once we remve the useEngagementStatusByBrand feature flag, we can remove that max-lines-per-function
// eslint exception
// eslint-disable-next-line max-lines-per-function
export function EngagementDetails({
  engagementId,
  engagement,
}: EngagementDetailsProps): JSX.Element {
  useEffect(() => {
    instrumentation.viewedEngagementDetails(engagementId)
  }, [engagementId])
  const [project, setProject] = useState<ProjectDetail | null>(null)
  const [engagementToMoveToProduction, setEngagementToMoveToProduction] =
    useState<Engagement | null>(null)

  const showProjectDetail = () => {
    const formattedProject = formatProjectToProjectDetailContent(engagement.project)
    setProject(formattedProject)
  }

  let engagementStage = <EngagementStageTechnicalReview engagement={engagement} />

  const hideMilestones = isEngagementInactive(engagement) || engagement.ndaStage !== 'fully_signed'

  switch (engagement.stage) {
    case 'nda':
      engagementStage = <EngagementStageNda engagement={engagement} />
      break
    case 'initial_discussion':
    case 'tech_review':
    case 'trial_and_development':
    case 'negotiating_agreement':
    case 'pre_production':
    case 'production':
      engagementStage = <EngagementStageTechnicalReview engagement={engagement} />
      break
  }

  if (engagement.ndaStage !== 'fully_signed' && !hasOffPlatformNDA(engagement)) {
    engagementStage = <EngagementStageNda engagement={engagement} />
  }

  const showSidebar = [
    'initial_discussion',
    'tech_review',
    'trial_and_development',
    'negotiating_agreement',
    'pre_production',
    'production',
  ].includes(engagement.stage)
  if (showSidebar) {
    return (
      <ScreenContainer showSidebar={showSidebar}>
        <Grid container>
          <Grid item md={2.5} bgcolor="white" display={{ xs: 'none', md: 'block' }}>
            <Sidebar engagement={engagement} showProjectDetailFn={showProjectDetail} />
          </Grid>
          <Grid item xs={12} display={{ md: 'none' }}>
            <SidebarAccordion engagement={engagement} showProjectDetailFn={showProjectDetail} />
          </Grid>
          <Grid item xs={12} md={9.5} px={{ sm: 4 }} my={2}>
            <Heading engagement={engagement} showProjectDetailFn={showProjectDetail} />
            {hideMilestones ? null : (
              <Milestones
                engagement={engagement}
                setEngagementToMoveToProduction={setEngagementToMoveToProduction}
              />
            )}
            {engagementStage}
          </Grid>
        </Grid>
        <ProjectDetailModal
          project={project}
          engagementStatus={engagement.stage}
          onClose={() => setProject(null)}
        />
        <MoveToProductionConfirmationModal
          engagementToMoveToProduction={engagementToMoveToProduction}
          setEngagementToMoveToProduction={setEngagementToMoveToProduction}
        />
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer>
      <Heading engagement={engagement} showProjectDetailFn={showProjectDetail} />
      {hideMilestones ? null : (
        <Milestones
          engagement={engagement}
          setEngagementToMoveToProduction={setEngagementToMoveToProduction}
        />
      )}
      {engagementStage}
      <ProjectDetailModal
        project={project}
        engagementStatus={engagement.stage}
        onClose={() => setProject(null)}
      />
      <MoveToProductionConfirmationModal
        engagementToMoveToProduction={engagementToMoveToProduction}
        setEngagementToMoveToProduction={setEngagementToMoveToProduction}
      />
    </ScreenContainer>
  )
}

export type RouteParams = {
  engagementId: string
}
type Props = RouteComponentProps<RouteParams>

export default function EngagementDetailsScreen({ match }: Props): JSX.Element {
  const { engagementId } = match.params
  const { data: engagement, isLoading } = useEngagementDetails(engagementId)

  if (isLoading) {
    return <Loading />
  }

  if (!engagement) {
    return <FourOhFour />
  }

  return <EngagementDetails engagementId={engagementId} engagement={engagement} />
}
