import { instrumentation as coreInstrumentation } from '@partnerslate/core'
import { getUnixTime } from 'date-fns'
import { CompanyTypeEnum } from 'partnerslate-models'
import { call, put } from 'redux-saga/effects'

import { Toast } from '@/components'
import config from '@/config'
import { Routes } from '@/constants'
import ApiService from '@/helpers/ApiService'
import { deleteAuth, getAuth, setAuth } from '@/helpers/auth'
import history from '@/helpers/history'
import mixpanel from '@/helpers/mixpanel'
import { ActionCreators } from '@/redux'
import { hydrateUserPayload, UserResponse } from '@/redux/api-payloads'

export function* setUserSession({ payload }: StandardAction<UserResponse>): GeneratorType {
  const user = payload.data || {}
  const { token } = user
  if (token) {
    // link mixpanel Distinct IDs together when possible
    coreInstrumentation.identify({
      userId: user.id,
      emailAddress: user.email,
      companyId: user.companies[0].id,
    })
    mixpanel.openApp()
    if (config.isProduction) {
      const IntercomValues: Record<CompanyTypeEnum, string> = {
        brand: 'Food Brand',
        manufacturer: 'Manufacturer',
      }
      ;(window as any).Intercom('boot', {
        /* eslint-disable camelcase */
        app_id: 'drsa2xvc',
        name: user.name,
        email: user.email,
        user_id: user.id,
        created_at: getUnixTime(new Date(user.dateJoined)), // Signup date as a Unix timestamp
        // @ts-ignore not a string yet
        type: IntercomValues[user.company.companyType],
        vertical_padding: 60,
        /* eslint-enable camelcase */
      })
    }
    yield call(setAuth, token)
    yield call(ApiService.setupInstance, token)
  }
}

export function* verifyEmailSuccess({ payload }: StandardAction<UserResponse>): GeneratorType {
  const user = payload.data
  const { token } = user
  if (token) {
    yield call(ApiService.setupInstance, token)
    yield call(history.push, Routes.CompanyProfile)
  } else {
    yield call(history.push, Routes.Login)
  }
}

export function* logout(): GeneratorType {
  yield call(deleteAuth)
  yield call(ApiService.setupInstance, undefined)

  if (config.isProduction) {
    ;(window as any).Intercom('shutdown')
  }
  window.location.href = Routes.Login
}

export function* resetPasswordSuccess(): GeneratorType {
  Toast.success('Success', 'Your password is set')

  yield call(() => {
    window.location.href = Routes.Login
  })
}

export function* startup(): GeneratorType {
  const ps = (window as any).partnerSlate
  if (ps && ps.hijack) {
    const hijackToken = ps.hijack.token as JWTTokens
    yield call(setAuth, hijackToken)
  }

  const token = getAuth()
  if (token) {
    yield call(ApiService.setupInstance, token)
    yield put(ActionCreators.api.makeRequest.dispatch(hydrateUserPayload()))
  }
}

export default { setUserSession }
