/* eslint-disable camelcase */
/* eslint-disable max-lines */
import type { ApiPayload, ErrorActionPayloadType, ErrorType } from '@partnerslate/radio-dispatch'
import { Card } from '@stripe/stripe-js'
import type { CompanyPayload } from 'partnerslate-models'
import { Company, FieldOption, User } from 'partnerslate-models'

import type { CompanyFilter } from '@/components/company-search-filter'
import { ProductTypes } from '@/constants'
import services, { endpoints } from '@/helpers/services'
import { ActionCreators as AC } from '@/redux'

export interface LoginPayload {
  password: string
  username: string
}
export interface UpdateUserPayload {
  email: string
  firstName: string
  lastName: string
  jobTitle: string
}

export interface UpdateUserAPIPayload {
  email: string
  first_name: string
  last_name: string
  job_title: string
}
export interface PaginatedData<T = unknown> {
  next: string | null
  previous: string | null
  count: number
  data: [T]
}

export interface CommonResponse {
  statusCode: number
}

export interface SuccessResponse<D = unknown> extends CommonResponse {
  data: D
}

export type Response<D> = SuccessResponse<D> & ErrorType

export type UserAndCompany = User & { companies: Array<Company> }

export type UserResponse = Response<UserAndCompany>

export interface ResetPasswordPayload {
  username: string
}
export interface VerifyEmailPayload {
  username: string
}

export interface VerifyEmailPostPayload {
  token: string
}
export interface ResetPasswordChangePayload {
  password: string
  token: string
}

export interface FileResponse {
  data: {
    data: {
      id: string
      url: string
    }
  }
}

export type UpdateCardResponse = { paymentMethod: Card } & {
  meta: { companySlug: string; subscriptionId: string }
}

export type DeleteCardResponse = { meta: { companySlug: string } }

export type FieldOptionSuccessResponse = SuccessResponse<Array<FieldOption>>

export type CompanyResponse = SuccessResponse<Company>

export interface SearchCompaniesPayload {
  text: string
  url?: string
  filters?: Record<string, CompanyFilter>
}

export interface SearchCompaniesAPIPayload {
  text: string
  url?: string
  filters?: Record<string, string>
}

export type SearchCompaniesResponseData = {
  count: number
  next: string
  previous: string
  results: Array<Company>
}

export type SearchCompaniesResponse = SuccessResponse<SearchCompaniesResponseData>

export interface ChangePasswordPayload {
  old_password: string
  new_password: string
}

export const loginPayload = (
  data: LoginPayload,
): ApiPayload<typeof services, LoginPayload, UserResponse | undefined> => ({
  serviceKey: endpoints.login,
  successActionCreator: AC.user.loginSuccess.dispatch,
  data,
})

export const updateUserPayload = (
  data: UpdateUserPayload,
): ApiPayload<typeof services, UpdateUserAPIPayload, UserResponse | undefined> => ({
  serviceKey: endpoints.updateMe,
  successActionCreator: AC.user.updateUserSuccess.dispatch,
  data: {
    email: data.email,
    first_name: data.firstName,
    last_name: data.lastName,
    job_title: data.jobTitle,
  },
  meta: {
    toast: true,
  },
})

export const deleteAccountPayload = (): ApiPayload<typeof services, undefined> => ({
  serviceKey: endpoints.deleteMe,
  successActionCreator: AC.user.deleteUserSuccess.dispatch,
  data: undefined,
})

export const updateCompanyPayload = (
  company: Company,
): ApiPayload<typeof services, CompanyPayload, CompanyResponse | undefined> => ({
  serviceKey: endpoints.updateCompany,
  successActionCreator: AC.company.updateCompanySuccess.dispatch,
  data: company.toJSON(),
  meta: {
    toast: true,
  },
})

export const verifyEmailPayload = (
  data: VerifyEmailPayload,
): ApiPayload<typeof services, VerifyEmailPayload> => ({
  serviceKey: endpoints.verifyEmailGet,
  data,
  meta: {
    toast: true,
  },
})

export const verifyEmailPostPayload = (
  data: VerifyEmailPostPayload,
): ApiPayload<typeof services, VerifyEmailPostPayload, UserResponse | undefined> => ({
  serviceKey: endpoints.verifyEmailPost,
  successActionCreator: AC.user.verifyEmailSuccess.dispatch,
  data,
  meta: {
    toast: true,
  },
})

export const resetPasswordPayload = (
  data: ResetPasswordPayload,
): ApiPayload<typeof services, ResetPasswordPayload> => ({
  serviceKey: endpoints.getResetPassword,
  successActionCreator: AC.user.resetPasswordSuccess.dispatch,
  data,
})

export const resetPasswordChangePayload = (
  data: ResetPasswordChangePayload,
): ApiPayload<typeof services, ResetPasswordChangePayload> => ({
  serviceKey: endpoints.postResetPassword,
  successActionCreator: AC.user.resetPasswordChangeSuccess.dispatch,
  data,
})

export const hydrateUserPayload = (): ApiPayload<
  typeof services,
  undefined,
  UserResponse | undefined,
  ErrorActionPayloadType | undefined
> => ({
  serviceKey: endpoints.me,
  data: undefined,
  successActionCreator: AC.user.hydrateUserSuccess.dispatch,
  errorActionCreator: AC.user.hydrateUserFailure.dispatch,
})

export const logoutPayload = (): ApiPayload<typeof services, undefined, Response<undefined>> => ({
  serviceKey: endpoints.logoutUser,
  data: undefined,
})

export const fetchCompanyPayload = (
  slug: string,
): ApiPayload<typeof services, string, CompanyResponse | undefined> => ({
  serviceKey: endpoints.fetchCompany,
  data: slug,
  meta: {
    slug,
  },
  successActionCreator: AC.company.fetchCompanySuccess.dispatch,
})

export const changePasswordPayload = (data: {
  password: string
  oldPassword: string
}): ApiPayload<typeof services, ChangePasswordPayload> => ({
  serviceKey: endpoints.changePassword,
  data: { old_password: data.oldPassword, new_password: data.password },
  meta: {
    toast: true,
  },
})

export const createCheckoutSessionPayload = ({
  product,
  companySlug,
  mode,
}: {
  product?: ProductTypes
  companySlug: string
  mode: 'subscription' | 'setup'
}): ApiPayload<
  typeof services,
  { product?: ProductTypes; companySlug: string; mode: 'subscription' | 'setup' }
> => ({
  serviceKey: endpoints.createCheckoutSession,
  data: { product, companySlug, mode },
  successActionCreator: AC.company.openStripeSession.dispatch,
})

export const createCancelSubscriptionPayload = (
  subscriptionId: string,
  companySlug: string,
): ApiPayload<
  typeof services,
  { subscriptionId: string },
  { meta: { companySlug: string } } | undefined
> => ({
  serviceKey: endpoints.cancelSubscription,
  data: { subscriptionId },
  successActionCreator: AC.company.cancelSubscriptionSuccess.dispatch,
  meta: {
    companySlug,
    toast: true,
  },
})

export const updateSubscriptionPayload = (
  data: UpdateCardResponse,
): ApiPayload<typeof services, { cardId: string; subscriptionId: string }, unknown> => ({
  serviceKey: endpoints.updateSubscription,
  data: { cardId: data.paymentMethod.id, subscriptionId: data.meta.subscriptionId },
  meta: {
    toast: true,
  },
})

export const deleteCardPayload = (data: {
  cardId: string
  subscriptionId: string
  companySlug: string
}): ApiPayload<
  typeof services,
  { cardId: string; subscriptionId: string; companySlug: string },
  DeleteCardResponse | undefined
> => ({
  serviceKey: endpoints.deleteCard,
  successActionCreator: AC.company.deleteCardSuccess.dispatch,
  data,
  meta: {
    toast: true,
    companySlug: data.companySlug,
  },
})

export const searchCompaniesPayload = (
  appData: SearchCompaniesPayload,
): ApiPayload<
  typeof services,
  SearchCompaniesAPIPayload,
  (SearchCompaniesResponse & { meta: { searchData: SearchCompaniesPayload } }) | undefined
> => {
  let filterParam: Record<string, string> = {}
  const { filters } = appData

  if (filters) {
    Object.keys(filters).forEach((key) => {
      const values = filters[key].length > 0 ? filters[key] : undefined
      if (values) {
        filterParam = { ...filterParam, [key]: values.map((v) => v.id).toString() }
      }
    })
  }
  const data = {
    ...appData,
    filters: filterParam,
  }

  return {
    serviceKey: endpoints.getCompanySearchResults,
    data,
    meta: {
      searchData: data,
    },
    successActionCreator: AC.company.searchCompaniesSuccess.dispatch,
  }
}
