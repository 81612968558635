/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
import { joinStr } from '@clearsummit/carabiners'
import { FieldOption, FieldTypes } from 'partnerslate-models'
import React from 'react'

import { ActionButton } from '@/components'
import Strings, { Images } from '@/constants'
import { formatFieldOptions } from '@/helpers'

import styles from './styles.scss'

const FieldIcons: Record<FieldTypes, string> = {
  [FieldTypes.servicesOffered]: Images.serviceType,
  [FieldTypes.manufacturerType]: Images.home,
  [FieldTypes.distributionType]: Images.truck,
  [FieldTypes.brandStage]: Images.stack,
  [FieldTypes.facilityLocation]: Images.mapPin,
  [FieldTypes.numberOfFacilityLocations]: Images.mapPin,
  [FieldTypes.packagingFormats]: Images.package,
  [FieldTypes.processingCapabilities]: Images.pieChart,
  [FieldTypes.certifications]: Images.award,
  [FieldTypes.productCategories]: Images.list,
  [FieldTypes.productionRunSize]: Images.barChart,
  [FieldTypes.additionalServices]: Images.plusCircle,
  [FieldTypes.businessType]: Images.serviceType,
  [FieldTypes.comanPreviousBrandSizes]: Images.list,
  [FieldTypes.diverseBackgrounds]: Images.list,
  [FieldTypes.diverseSocialImpacts]: Images.list,
}
interface Props {
  field: FieldTypes
  value: FieldOption | Array<FieldOption> | number | undefined
  className?: string | undefined
  editing: boolean
  setEditingField: (field: FieldTypes) => void
  setEditing: (editing: boolean) => void
  isOwnEditableCompany: boolean
}

export function CompanyFieldCard({
  value,
  field,
  className,
  editing,
  setEditingField,
  setEditing,
  isOwnEditableCompany,
}: Props): React.ReactElement | null {
  if (
    editing ||
    (typeof value !== 'number' && Array.isArray(value) && value.length > 0) ||
    (typeof value !== 'number' && !Array.isArray(value) && value?.label) ||
    typeof value === 'number'
  ) {
    let formattedValues = value as FieldOption | Array<FieldOption> | undefined
    if (
      typeof value !== 'number' &&
      !Array.isArray(value) &&
      field === FieldTypes.brandStage &&
      value
    ) {
      formattedValues = new FieldOption({
        ...value,
        label: `${value.title} (${value.subText})`,
      })
    } else if (
      typeof value !== 'number' &&
      Array.isArray(value) &&
      (field === FieldTypes.productCategories || field === FieldTypes.packagingFormats)
    ) {
      formattedValues = formatFieldOptions(value)
    }

    return (
      <>
        <div
          role="presentation"
          className={joinStr(styles.companyFieldCard, className)}
          onClick={() => editing && setEditingField(field)}
        >
          <div>
            <img
              className={styles.cardIcon}
              src={FieldIcons[field]}
              alt={`${Strings.general[field]} icon`}
            />
            <div>
              <h3>{Strings.general[field]}</h3>
              {Array.isArray(formattedValues) ? (
                formattedValues.reduce(
                  (acc: Array<JSX.Element>, curr: FieldOption): Array<JSX.Element> => {
                    acc.push(<p key={curr.label}>{curr.label}</p>)
                    return acc
                  },
                  [],
                )
              ) : typeof value !== 'number' ? (
                <p key={formattedValues?.label}>{formattedValues?.label}</p>
              ) : (
                <p>{value}</p>
              )}
            </div>
          </div>
          <div className={styles.btnArea}>
            {isOwnEditableCompany ? (
              editing ? (
                <ActionButton
                  className={styles.button}
                  title="edit"
                  onClick={() => setEditingField(field)}
                  ariaLabel={Strings.profile.editField(field)}
                  iconAlt={Strings.profile.editField(field)}
                />
              ) : (
                <ActionButton
                  className={styles.button}
                  title="edit"
                  onClick={() => {
                    setEditing(true)
                    setEditingField(field)
                  }}
                  ariaLabel={Strings.profile.editField(field)}
                  iconAlt={Strings.profile.editField(field)}
                />
              )
            ) : null}
          </div>
        </div>
        {editing ? <hr /> : null}
      </>
    )
  }

  return (
    <div role="presentation" className={joinStr(styles.companyFieldCard)}>
      <h3>{Strings.general[field]}</h3>
      <div className={styles.btnArea}>
        {isOwnEditableCompany ? (
          <ActionButton
            className={styles.button}
            title="add"
            onClick={() => {
              setEditing(true)
              setEditingField(field)
            }}
            ariaLabel={Strings.profile.editField(field)}
            iconAlt={Strings.profile.editField(field)}
          />
        ) : null}
      </div>
    </div>
  )
}

CompanyFieldCard.defaultProps = {
  className: undefined,
}

export default CompanyFieldCard
