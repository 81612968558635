import { dispatchReducer, ErrorActionPayloadType, runReducers } from '@partnerslate/radio-dispatch'
import produce from 'immer'
import { User } from 'partnerslate-models'
import { AnyAction } from 'redux'

import { UserResponse } from '@/redux/api-payloads'
import { userNormalizer } from '@/redux/schema'

import { noOp } from './shared-reducers'

export interface UserStoreState {
  user: User
  accounts: Record<string, User>
}

// User Actions
export const ACTIONS = {
  START_UP: 'START_UP',
  SET_USER_SESSION: 'SET_USER_SESSION',
  CLEAR_USER_SESSION: 'CLEAR_USER_SESSION',
  HYDRATE_USER_SUCCESS: 'USER/HYDRATE_USER_SUCCESS',
  HYDRATE_USER_FAILURE: 'USER/HYDRATE_USER_FAILURE',
  STORE_USER: 'STORE_USER',
  LOGOUT: 'LOGOUT',
  HYDRATE_USER: 'HYDRATE_USER',
  START_REQUEST: 'USER/START_REQUEST',
  END_REQUEST: 'USER/END_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USER/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_CHANGE_SUCCESS: 'USER/RESET_PASSWORD_CHANGE_SUCCESS',
  VERIFY_EMAIL_SUCCESS: 'USER/VERIFY_EMAIL_SUCCESS',
  UPDATE_USER_SUCCESS: 'USER/UPDATE_USER_SUCCESS',
  DELETE_ME_SUCCESS: 'USER/DELETE_ME_SUCCESS',
}

export const INITIAL_STATE: UserStoreState = {
  user: new User(),
  accounts: {},
}

const updateUser = produce((draft: UserStoreState, payload: UserResponse | undefined) => {
  const state = draft
  let normalized
  if (payload) {
    normalized = userNormalizer(payload.data)
  }
  const updatedUser =
    normalized && normalized.entities.user
      ? normalized.entities.user[normalized.result]
      : state.user
  state.user = updatedUser
})

const logout = produce((draft: UserStoreState) => {
  const state = draft
  state.user = new User()
})

export const reduxSet = {
  startUp: dispatchReducer<UserStoreState>(ACTIONS.START_UP, noOp),
  loginSuccess: dispatchReducer<UserStoreState, UserResponse>(ACTIONS.SET_USER_SESSION, updateUser),
  updateUserSuccess: dispatchReducer<UserStoreState, UserResponse>(
    ACTIONS.UPDATE_USER_SUCCESS,
    updateUser,
  ),
  hydrateUserSuccess: dispatchReducer<UserStoreState, UserResponse>(
    ACTIONS.HYDRATE_USER_SUCCESS,
    updateUser,
  ),
  storeUser: dispatchReducer<UserStoreState, UserResponse>(ACTIONS.STORE_USER, updateUser),
  verifyEmailSuccess: dispatchReducer<UserStoreState, UserResponse | undefined>(
    ACTIONS.VERIFY_EMAIL_SUCCESS,
    updateUser,
  ),
  hydrateUserFailure: dispatchReducer<UserStoreState, ErrorActionPayloadType | undefined>(
    ACTIONS.HYDRATE_USER_FAILURE,
    logout,
  ),
  logout: dispatchReducer<UserStoreState, User>(ACTIONS.LOGOUT, logout),
  resetPasswordSuccess: dispatchReducer<UserStoreState>(ACTIONS.RESET_PASSWORD_SUCCESS, noOp),
  resetPasswordChangeSuccess: dispatchReducer<UserStoreState>(
    ACTIONS.RESET_PASSWORD_CHANGE_SUCCESS,
    noOp,
  ),
  deleteUserSuccess: dispatchReducer<UserStoreState>(ACTIONS.DELETE_ME_SUCCESS, logout),
}

export const reducer = (
  state: UserStoreState = { ...INITIAL_STATE },
  action: AnyAction,
  // @ts-ignore @Update radio dispatch to use AnyAction
): UserStoreState => runReducers(state, action, reduxSet)
