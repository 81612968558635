/* eslint-disable max-lines */
import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  ScopedCssBaseline,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { MessagingContainer } from '@partnerslate/feature-messaging'
import cn from 'classnames'
import format from 'date-fns/format'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'

import Loading from '@/components/loading'
import { PushedRight } from '@/components/mui-extensions'
import { Images } from '@/constants'
import { Engagement, EngagementPerspective, MessageChannelConnection } from '@/domain/engagements'
import {
  ComposedContentBlock,
  mentionBlock,
  MessagesType,
  StructuredContent,
  StructuredMessage,
  StructuredMessageChannelContents,
  textBlock,
} from '@/domain/messages'
import { instrumentation } from '@/helpers'
import { OUR_THEME } from '@/helpers/mui'
import { useMessageChannel } from '@/helpers/services/queries'

import styles from './styles.scss'

type EmptyStateProps = { isPartnerPerspective: boolean }
function EmptyStateIntraNotes({ isPartnerPerspective }: EmptyStateProps) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" marginBottom={6}>
      <Box width="70%">
        <Typography variant="h5" marginBottom={5} textAlign="center">
          <Typography variant="h5" fontWeight="bold" display="inline" sx={{ fontStyle: 'italic' }}>
            Leave notes for your team
          </Typography>{' '}
          so everyone can stay informed and can jump on open questions or blockers
        </Typography>
        <Box
          component="img"
          sx={{ width: { xs: 0, md: '100%' } }}
          alt="Intra notes description"
          src={
            isPartnerPerspective ? Images.intraComanMessagesEmpty : Images.intraBrandMessagesEmpty
          }
        />
      </Box>
    </Box>
  )
}

function EmptyStateInterMessages({ isPartnerPerspective }: EmptyStateProps) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" marginBottom={6}>
      <Box width="70%">
        <Typography variant="h5" marginBottom={5} textAlign="center">
          <Typography variant="h5" fontWeight="bold" display="inline" sx={{ fontStyle: 'italic' }}>
            Collaborate with the {isPartnerPerspective ? 'brand' : 'manufacturer'}
          </Typography>{' '}
          and your team in one place for a more streamlined onboarding process
        </Typography>
        <Box
          component="img"
          sx={{ width: { xs: 0, md: '100%' } }}
          alt="Messages description"
          src={Images.interMessagesEmpty}
        />
      </Box>
    </Box>
  )
}

type Props = {
  channelConnection: MessageChannelConnection
  isExpanded?: boolean
  messagesType: MessagesType
  engagement: Engagement
}
export default function ConnectedNotes({
  channelConnection,
  messagesType,
  engagement,
  isExpanded = false,
}: Props): JSX.Element {
  const { data, isLoading } = useMessageChannel(channelConnection)

  useEffect(() => {
    instrumentation.viewedTeamNotes(channelConnection.channelId)
  }, [channelConnection.channelId])

  if (isLoading || data === undefined) {
    return <Loading />
  }

  const isIntraNotes = messagesType === 'intra'
  const isPartnerPerspective = engagement.perspective === EngagementPerspective.Partner

  return (
    <MessagingContainer
      channelId={channelConnection.channelId}
      messageOrder={isIntraNotes ? 'desc' : 'asc'}
      emptyState={
        isIntraNotes ? (
          <EmptyStateIntraNotes isPartnerPerspective={isPartnerPerspective} />
        ) : (
          <EmptyStateInterMessages isPartnerPerspective={isPartnerPerspective} />
        )
      }
      shouldShowInput={isExpanded}
      isPartnerPerspective={isPartnerPerspective}
      inputLabel={isIntraNotes ? 'New Note' : 'New message'}
      inputPlaceholder={
        isIntraNotes
          ? 'Leave a note for your team or type @ to tag a specific person'
          : 'Message everyone or type @ to tag a specific person'
      }
    />
  )
}

function FullHeightBox({ children }: { children: ReactNode }) {
  return <Box height="100%">{children}</Box>
}

type MentionsProps = {
  // eslint-disable-next-line react/require-default-props
  className?: string | undefined
  mentionsSuggestions: {
    id: string
    display: string
  }[]
  mentionsPortalRef: React.RefObject<HTMLElement>
}

const MentionsTextArea = React.forwardRef<HTMLInputElement, MentionsProps>(
  (props: MentionsProps, ref) => {
    // MUI passes it's own classes here so we need className
    const { className, mentionsSuggestions, mentionsPortalRef, ...other } = props

    if (!mentionsPortalRef.current) {
      return null
    }

    return (
      <MentionsInput
        {...other}
        className={cn(className, styles.mentionsInput, 'mentionsInput')}
        inputRef={ref}
        a11ySuggestionsListLabel="Suggested mentions"
        suggestionsPortalHost={mentionsPortalRef.current}
        style={{
          control: {
            ...OUR_THEME.typography.body1,
          },
          '&multiLine': {
            highlighter: {
              ...OUR_THEME.typography.body1,
            },
          },
        }}
      >
        <Mention
          data={mentionsSuggestions}
          trigger="@"
          displayTransform={(_id: string, display: string) => `@${display}`}
        />
      </MentionsInput>
    )
  },
)

export const convertComposeValueToContentBlocks = (input: string): ComposedContentBlock[] => {
  // eslint-disable-next-line prefer-regex-literals
  const mentionRegex = new RegExp('@\\[(?<display>[^\\]]+)\\]\\((?<id>\\w+)\\)', 'g')
  let processedIndex = 0
  const messageBlocks = []

  let match: RegExpExecArray | null

  // eslint-disable-next-line no-cond-assign
  while ((match = mentionRegex.exec(input)) !== null) {
    // grab any text preceding this match and turn it into a text block
    const precedingContent = input.slice(processedIndex, match.index)
    messageBlocks.push(textBlock(precedingContent))

    // grab the match and turn it into a mention block
    const subjectId = match.groups!.id
    messageBlocks.push(mentionBlock(subjectId))

    // reset processedIndex to past this match
    processedIndex = mentionRegex.lastIndex
  }

  // grab anything remaining and turn it into a text block
  const finalContent = input.slice(processedIndex)
  if (finalContent !== '') {
    messageBlocks.push(textBlock(finalContent))
  }

  return messageBlocks
}

type NotesProps = {
  channelContents: StructuredMessageChannelContents
  onPostNewNote: (contents: ComposedContentBlock[]) => void
  channelId: string
  isExpanded?: boolean
}
// eslint-disable-next-line max-lines-per-function
export function Notes({
  channelContents,
  onPostNewNote,
  channelId,
  isExpanded,
}: NotesProps): JSX.Element {
  const mentionsPortalRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [draftNoteMessage, setDraftNoteMessage] = useState<string>('')

  useEffect(() => {
    if (listRef.current) {
      const newListHeight = listRef.current.scrollHeight

      listRef.current.scrollTop = newListHeight
    }

    // diff on length of messages here to prevent ping from useQuery from triggering a scroll
  }, [channelContents.timeOrderedMessages.length])

  const postDraftAsNote = () => {
    const messageBlocks = convertComposeValueToContentBlocks(draftNoteMessage)

    if (messageBlocks.some((block) => block.type === 'mention')) {
      instrumentation.sentMessageWithMention(channelId)
    }

    onPostNewNote(messageBlocks)
    setDraftNoteMessage('')
    instrumentation.sentNoteMessage(channelId)
  }

  const handleDraftKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === 'Enter' && !e.getModifierState('Shift')) {
      e.preventDefault()
      postDraftAsNote()
    }

    if (e.key === '@') {
      instrumentation.startedMentioningSomeoneInNotes(channelId)
    }
  }

  const handleDraftMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDraftNoteMessage(e.target.value)
  }

  const hasNotes = Boolean(channelContents.timeOrderedMessages.length)

  const getSuggestions = () => {
    return channelContents.channelMembers.map((member) => {
      return {
        id: member.id,
        display: member.displayName,
      }
    })
  }

  let listStyles = { flex: '1', marginBottom: '10px', overflowY: 'auto' }
  if (!isExpanded) {
    listStyles = { ...listStyles, marginBottom: '0', overflowY: 'hidden' }
  }

  return (
    <ScopedCssBaseline component={FullHeightBox}>
      <Box display="flex" flexDirection="column" height="100%">
        {hasNotes ? (
          <List ref={listRef} component="ul" sx={listStyles}>
            {channelContents.timeOrderedMessages.map((message) => (
              <PastNote key={message.id} message={message} />
            ))}
          </List>
        ) : (
          <EmptyNoteState isExpanded={isExpanded} />
        )}
        {isExpanded && (
          <Stack spacing={2} className={styles.mentionsPortal} ref={mentionsPortalRef}>
            <Box>
              <TextField
                fullWidth
                label="Message your team"
                placeholder="Message your team or type @ to tag a specific person"
                multiline
                maxRows={3}
                value={draftNoteMessage}
                onChange={handleDraftMessageChange}
                onKeyDown={handleDraftKeyDown}
                onFocus={() => instrumentation.focusedTeamNotesTextField(channelId)}
                InputProps={{
                  // @ts-ignore
                  inputComponent: MentionsTextArea,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  mentionsPortalRef,
                  mentionsSuggestions: getSuggestions(),
                }}
                sx={{ marginBottom: 1 }}
              />
              <PushedRight>
                <Button variant="contained" onClick={postDraftAsNote}>
                  Post
                </Button>
              </PushedRight>
            </Box>
          </Stack>
        )}
      </Box>
    </ScopedCssBaseline>
  )
}

type EmptyNoteProps = {
  isExpanded?: boolean
}
function EmptyNoteState({ isExpanded }: EmptyNoteProps) {
  const iconWidth = isExpanded ? '20vh' : OUR_THEME.spacing(18)
  const Icon = styled('img')({
    width: iconWidth,
  })

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      alignItems="center"
      mt={3}
      mb={1}
      sx={{ overflowY: 'auto' }}
    >
      <Box maxWidth={OUR_THEME.spacing(30)} mb={1.5}>
        <Icon src={Images.peopleChatting} alt="People Chatting" />
      </Box>
      <Typography variant="body1" color="text.secondary" maxWidth="336px" textAlign="center">
        This is a great place to ask for feedback on a document or request that a task be fulfilled
        from your team.
      </Typography>
    </Box>
  )
}

type PastNoteProps = {
  message: StructuredMessage
}
function PastNote({ message }: PastNoteProps): JSX.Element {
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        flexDirection: 'column',
      }}
      disableGutters
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h6" color="text.primary">
          {message.authorName}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {format(message.timestamp, 'MMM d, hh:mm aaa')}
        </Typography>
      </Stack>
      <Card
        variant="outlined"
        sx={{
          width: '100%',
          px: 2,
          py: 1,
          whiteSpace: 'pre-wrap',
        }}
      >
        <StructuredMessage structuredContents={message.structuredContents} />
      </Card>
    </ListItem>
  )
}

type StructuredMessageProps = {
  structuredContents: StructuredContent[]
}
function StructuredMessage({ structuredContents }: StructuredMessageProps) {
  return (
    <>
      {structuredContents.map(({ type, text }) => {
        if (type === 'text') {
          return (
            <Typography variant="body2" component="span">
              {text}
            </Typography>
          )
        }

        if (type === 'mention') {
          return (
            <Typography variant="body2" component="span" color="primary.main">
              {text}
            </Typography>
          )
        }

        return null
      })}
    </>
  )
}
