import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Dispatch } from 'redux'

import { instrumentation } from '@/helpers'
import { reduxSet as userAC } from '@/redux/user'

interface DispatchProps {
  logout: () => void
}

export function LogoutScreen({ logout }: DispatchProps & RouteComponentProps): null {
  const queryClient = useQueryClient()

  queryClient.clear()

  useEffect(() => {
    instrumentation.logout()

    // the logout saga handles redirecting to login page
    // /partnerslate/frontend/web/src/sagas/user.ts

    logout()
  }, [logout])

  return null
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  logout: () => dispatch(userAC.logout.dispatch()),
})

export default withRouter(connect(undefined, mapDispatchToProps)(LogoutScreen))
