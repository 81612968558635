import * as yup from 'yup'

import Strings, { Regex } from '@/constants'

export const defaultPasswordPatternSchema = yup
  .string()
  .min(8, Strings.auth.passwordValidErr.min)
  .max(50, Strings.auth.passwordValidErr.max)
  .matches(Regex.oneNumber, Strings.auth.passwordValidErr.oneNumber)
  .matches(Regex.oneSpecialChar, Strings.auth.passwordValidErr.oneSpecialChar)
  .required(Strings.auth.signup.passwordSubtext)

export const defaultConfirmPassword = yup
  .string()
  .oneOf([yup.ref('password')], Strings.auth.signup.passwordMatchError)
  .required(Strings.auth.signup.confirmRequired)

export const LeadJoinSchema = yup.object().shape({
  password: defaultPasswordPatternSchema,
  confirmPassword: defaultConfirmPassword,
  tos: yup.boolean().required(Strings.inputs.tos.error).oneOf([true], Strings.inputs.tos.error),
})
