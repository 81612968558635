/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'

import { AppContentID } from '@/constants'

import { ModalActions } from './constants'
import eventManager from './event-manager'
import DeleteAccountModal from './modals/delete-account'
import DeleteCardModal from './modals/delete-card'
import DowngradeSubscriptionModal from './modals/downgrade-subscription'
import SearchPaywallModal from './modals/search-paywall'
import SubscriptionSuccessModal from './modals/subscription-success'
import UnsavedChangesModal from './modals/unsaved-changes'
import styles from './styles.scss'
import ModalTypes from './types'

const Modals: ModalTypes = {
  deleteAccount: DeleteAccountModal,
  deleteCard: DeleteCardModal,
  subscriptionSuccess: SubscriptionSuccessModal,
  downgradeSubscription: DowngradeSubscriptionModal,
  searchPaywall: SearchPaywallModal,
  unsavedChanges: UnsavedChangesModal,
}

function Modal() {
  const [currentType, setCurrentType] = useState<keyof ModalTypes | null>(null)
  const [propsState, setPropsState] = useState<Record<string, unknown> | undefined>(undefined)

  const setContent = (props: Record<string, unknown>): void => {
    setPropsState(props)
  }

  const setModal = (type: keyof ModalTypes, props: Record<string, unknown> | undefined): void => {
    setPropsState(props)
    setCurrentType(type)
  }

  const getModalComponent = (): typeof Modals[keyof typeof Modals] | null => {
    let modalNode = null
    if (currentType !== null && Modals[currentType]) {
      modalNode = Modals[currentType]
    }
    return modalNode
  }

  const removeModal = (): void => {
    eventManager.emit(ModalActions.ACTIVATE_APP_SCROLL)
    setCurrentType(null)
    setPropsState({})
    const contentToRemoveBlur = document.getElementById(AppContentID)
    if (contentToRemoveBlur) {
      contentToRemoveBlur.style.filter = ''
    }
  }

  const renderModal = (type: keyof ModalTypes, props?: Record<string, unknown> | undefined) => {
    const contentToBeBlurred = document.getElementById(AppContentID)
    if (contentToBeBlurred) {
      contentToBeBlurred.style.filter = 'blur(6px)'
    }
    setModal(type, props)
  }

  useEffect(() => {
    eventManager
      .register(
        ModalActions.RENDER_MODAL,
        (type: keyof ModalTypes, props?: Record<string, unknown> | undefined) =>
          renderModal(type, props),
      )
      .register(ModalActions.REMOVE_MODAL, () => removeModal())
      .register(ModalActions.SET_CONTENT, (content: any) => setContent(content))
      .emit(ModalActions.DID_MOUNT)

    if (currentType) {
      eventManager.emit(ModalActions.RENDER_MODAL, currentType)
    }

    return () => {
      eventManager.clear(ModalActions.RENDER_MODAL).emit(ModalActions.WILL_UNMOUNT)
    }
    // For whatever reason, following this rule broke profile image uploading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ModalNode = getModalComponent()
  if (ModalNode) {
    return (
      <div className={styles.modal}>
        {/* @ts-ignore */}
        <ModalNode {...propsState} close={removeModal} />
      </div>
    )
  }
  return null
}

export default withRouter(Modal)
